import React, { useState, useEffect, useRef } from 'react';

function Header() {

    return (
        <React.Fragment>
           <nav>
             <img src="/images/swm.png" className="logo" alt="Swing with me" height="44"/>
           </nav>
        </React.Fragment>
    );
}

export default Header;
import React, { useState, useEffect, useRef } from 'react';
import EventDetails from './EventDetails'
import Header from './Header'
import MapView from './MapView';
import { BrowserRouter, Routes, Route } from "react-router";

function App() {

    return (
        <React.Fragment>
           <header id="header">
                <Header />
           </header>
           <div className="App" id="app">
              <BrowserRouter>
                <Routes>
                  <Route path="/" element={<MapView />} />
                  <Route path="event/:eventId" element={<EventDetails />} />
                </Routes>
              </BrowserRouter>
           </div>
        </React.Fragment>
    );
}

export default App;